<template>
  <NuxtLink
    ref="link"
    v-editable="blok"
    :to="
      isDesktopNavigation && hasChildMenu
        ? undefined
        : formatLink(blok.link?.cached_url)
    "
    :target="blok.link?.target"
    :rel="isExternalLink(blok.link?.cached_url) ? 'external' : undefined"
    :style="customColorTheme"
    :class="{
      'lg:hidden': blok.onlyShowOnMobile,
      'is-active': isActive,
    }"
    tabindex="0"
    role="menuitem"
    class="ease-out-expo inline-flex cursor-pointer items-center gap-2 transition-colors duration-700 hover:text-[--custom-theme-hover-text-color]"
    @click="onClick($event)"
  >
    {{ blok.label }}

    <UiIcon
      v-if="icon && !hideIcon"
      :icon="blok.childMenu?.length ? 'chevron-down' : icon"
      :class="iconClasses"
      :size="iconSize"
    />
  </NuxtLink>
</template>

<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'
import type { BlockNavigationMenuItemStoryblok } from '@/types/storyblok'
import type { Icon, IconSize } from '@/components/ui/UiIcon.vue'
import type { NavigationMenuItemCustomColorTheme } from './types'
import { formatLink, isExternalLink } from '@/utils/link-utils'

const props = withDefaults(
  defineProps<{
    blok: BlockNavigationMenuItemStoryblok
    iconClasses?: string
    iconSize?: IconSize
    hideIcon?: boolean
    customColorTheme?: NavigationMenuItemCustomColorTheme
    isDesktopNavigation?: boolean
  }>(),
  {
    iconClasses: '',
    iconSize: 'relative',
    hideIcon: false,
    customColorTheme: undefined,
  },
)
const link = ref<HTMLElement | null>(null)
const icon = computed(() => props.blok.icon as Icon)
const isActive = ref<boolean>(false)
const hasChildMenu = computed(() => props.blok.childMenu?.length ?? 0)

onClickOutside(link, () => {
  isActive.value = false
})

function onClick($event: Event): void {
  if (!props.isDesktopNavigation || !hasChildMenu.value) {
    return
  }

  $event.preventDefault()

  isActive.value = !isActive.value
}
</script>
